import styled from 'styled-components'
import variables from '../variables'
import { Heading } from '../../components'
import { headerStyles } from '../global'

export const Container = styled.div`
  width: 100%;
  max-width: 55em;
  margin: 50px auto;
  padding: 0 40px;

  @media ${variables.media.md} {
    padding: 0;
  }
`

export const UseCaseHeader = styled(Heading)`
  ${headerStyles.headerXSmall};
  font-family: ${variables.fonts.body};
  color: ${variables.colors.oracle.dark};
  margin: 50px 0;
`
