import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { PageLayout } from '../layouts'
import {
  PageHeader, PopularUseCaseCard, Section, SimpleCallToActionBanner
} from '../components'
import * as styles from '../styles/pages/showcases'
import variables from '../styles/variables'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function ShowcasesPage({ data, location }) {
  const contentEdge = _.first(data.allYaml.edges)
  const content = contentEdge.node

  const firstChild = (
    <PageHeader
      header={content.header}
      info={content.headerInfo}
    />
  )

  const useCaseIdParam = queryString.parse(location.search).useCaseId || ''

  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={content.metaDescription}
      sharingTitle={content.title}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{content.title}</title>
      </Helmet>
      <Section>
        <styles.Container>
          <Section>
            {_.map(content.useCases, (useCase, i) => {
              return (
                <PopularUseCaseCard
                  key={i}
                  useCaseIdParam={useCaseIdParam}
                  useCase={useCase}
                />
              )
            })}
          </Section>

        </styles.Container>
      </Section>
      <SimpleCallToActionBanner />
    </PageLayout>
  )
}

ShowcasesPage.propTypes = propTypes

export const contentQuery = graphql`
  query GetAllUseCases {
    allYaml(filter: {file: {eq: "showcases"}}) {
      edges {
        node {
          title
          header
          headerInfo
          metaDescription

          useCases {
            id
            title
            summary
            content
            tags
            imageUrl
            blogPostUrl
            tutorialUrl
            statistics {
              bigText
              smallText
            }
            quote {
              text
              authorName
              authorPosition
              authorImg {
                childImageSharp {
                  fluid(maxWidth: 80, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
